<template>
  <div class="grid-container">
    <div class="grid-x grid-padding-x">
      <div class="cell small-12">
          <h1>Upload new CSV file</h1>
      </div>
    </div>
    <div class="grid-x grid-padding-x pad-top">
      <div class="cell small-12">
        <form class="file-form">
          <div class="grid-x grid-padding-x">
            <div class="cell small-12">
              <label>Select the date</label>
            </div>
          </div>
          <div class="grid-x grid-padding-x">
            <div class="cell small-12 medium-2">
              <select required v-model="dateDay">
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
                <option value="14">14</option>
                <option value="15">15</option>
                <option value="16">16</option>
                <option value="17">17</option>
                <option value="18">18</option>
                <option value="19">19</option>
                <option value="20">20</option>
                <option value="21">21</option>
                <option value="22">22</option>
                <option value="23">23</option>
                <option value="24">24</option>
                <option value="25">25</option>
                <option value="26">26</option>
                <option value="27">27</option>
                <option value="28">28</option>
                <option value="29">29</option>
                <option value="30">30</option>
                <option value="31">31</option>
              </select>
            </div>
            <div class="cell small-12 medium-3">
              <select required v-model="dateMonth">
                <option value="1">Jan</option>
                <option value="2">Feb</option>
                <option value="3">Mar</option>
                <option value="4">Apr</option>
                <option value="5">May</option>
                <option value="6">Jun</option>
                <option value="7">Jul</option>
                <option value="8">Aug</option>
                <option value="9">Sep</option>
                <option value="10">Oct</option>
                <option value="11">Nov</option>
                <option value="12">Dec</option>
              </select>
            </div>
            <div class="cell small-12 medium-3">
              <select required v-model="dateYear">
                <option value="2020">2020</option>
                <option value="2021">2021</option>
                <option value="2022">2022</option>
                <option value="2023">2023</option>
                <option value="2024">2024</option>
                <option value="2025">2025</option>
              </select>
            </div>
          </div>
          <div class="grid-x grid-padding-x">
            <div class="cell small-12 medium-6">
              <label>Select type of data</label>
              <select required v-model="dataType">
                <option value="0">Please choose:</option>
                <option value="1">Open Interest</option>
                <option value="2">Legacy - Futures only</option>
                <option value="3">Legacy - Futures &amp; Options</option>
                <option value="4">TFF - Futures only</option>
                <option value="5">TFF - Futures &amp; Options</option>
              </select>
            </div>
          </div>
          <div
            v-if="dataType === '1'"
            class="grid-x grid-padding-x">
            <div class="cell small-12 medium-6">
              <label>
                <input v-model="finalData" value="1" name="type" type="radio">
                Prelim
              </label>
              <label>
                <input v-model="finalData" value="2" name="type" type="radio">
                Final
              </label>
            </div>
          </div>
          <div class="grid-x grid-padding-x">
            <div class="cell small-12 medium-6">
              <label>Click the button below to select a file to upload</label>
              <br />
              <div class="file"></div>
              <div><p>{{ filename }}</p></div>
              <div class="pad-top">
                <button class="button">Upload</button>
              </div>
              <div class="UppyProgressBar"></div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div
      v-if="message.length > 0"
      class="message-wrap">
      <span class="close-message" v-on:click="reload()">X</span>
      <p class="message-text">{{ message }}</p>
    </div>
  </div>
</template>

<script>
import axios from '../axios';
import '@uppy/core/dist/style.css';
import '@uppy/file-input/dist/style.css';
import '@uppy/progress-bar/dist/style.css';

const Uppy = require('@uppy/core');
const UppyForm = require('@uppy/form');
const Transloadit = require('@uppy/transloadit');
const ProgressBar = require('@uppy/progress-bar');
const FileInput = require('@uppy/file-input');

export default {
  name: 'AddFile',
  data() {
    return {
      dataType: 0,
      finalData: '1',
      filename: '',
      message: '',
      dateDay: new Date().getDate(),
      dateMonth: (new Date().getMonth() + 1),
      dateYear: new Date().getFullYear(),
    };
  },
  computed: {
    authData() {
      return this.$store.getters.authData;
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
  },
  methods: {
    saveFile(result) {
      const postData = {};
      postData.files = result;
      postData.dataType = this.dataType;
      postData.finalData = this.finalData;
      postData.date = `${this.dateYear}-${this.dateMonth}-${this.dateDay}`;
      axios.post(`/files/add.json?auth=${this.authData.token}&user=${this.authData.userId}&cage=${this.authData.cage}&level=${this.userLevel}`, postData)
        .then((response) => {
          if (response.data.response === true) {
            this.message = 'The file has been uploaded successfully';
          } else {
            this.message = 'The file could not be uploaded - please check the details';
          }
        });
    },
    reload() {
      // eslint-disable-next-line
      location.reload();
    },
    clearMessage() {
      this.message = '';
    },
  },
  mounted() {
    Uppy({
      debug: true,
      autoProceed: false,
      restrictions: {
        maxFileSize: (1000000 * 1000),
        maxNumberOfFiles: 3,
        minNumberOfFiles: 0,
      },
    })
      .use(UppyForm, {
        target: '.file-form',
        multipleResults: true,
        triggerUploadOnSubmit: true,
        submitOnSuccess: false,
      })
      .use(FileInput, {
        id: 'FileInput',
        target: '.file',
        pretty: true,
        inputName: 'file',
      })
      .on('file-added', (res) => {
        this.filename = res.name;
      })
      .use(ProgressBar, {
        target: '.UppyProgressBar',
        hideAfterFinish: false,
      })
      .use(Transloadit, {
        service: 'https://api2.transloadit.com',
        params: {
          auth: { key: 'db6a1d40944f11e5a0a165fb32f6e49b' },
          template_id: '6adfd02747614c748c4cb9274a2ffce0',
        },
        waitForEncoding: true,
        waitForMetadata: false,
        importFromUploadURLs: false,
        alwaysRunAssembly: false,
        signature: null,
      })
      .on('complete', (result) => {
        this.saveFile(result);
      });
  },
};
</script>
